import styled from 'styled-components'
import { tokens } from '../theme'
const { borders, color, font, space, breakpoint } = tokens

export const CardHeader = styled.div<{ kind: string }>`
  width: 100%;
  color: ${color.grayscale.black};
  font-family: ${font.family.heading};
  font-size: ${font.size.h5};
  font-weight: ${font.weight.bold};
  text-align: left;
  display: block;
  margin-bottom: ${space.lg};
  @media (min-width: ${breakpoint.lg}) {
    display: none;
  }
`

export const CardInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  u {
    font-weight: ${font.weight.medium};
  }

  i {
    color: ${color.text.weak};
  }

  & ul {
    width: 100%;
    @media (max-width: ${breakpoint.lg}) {
      width: 100%;
    }
  }
`

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: ${space.xl};
`

export const Card = styled.a<{ kind: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-right: ${space.lg};
  border-radius: ${borders.radius.lg};
  overflow: hidden;
  width: 100%;
  transition: box-shadow 0.4s ease-in-out;
  background-color: ${({ kind }) => (kind === 'light' ? color.grayscale.lightBlue : color.grayscale.lightPurple)};
  @media (min-width: ${breakpoint.lg}) {
    min-width: auto;
    margin-right: 0;
    max-height: 100%;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`
