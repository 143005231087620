import styled from 'styled-components'
import { theme } from '@/client/components'
const { borders, breakpoint, color, font, space, zindex } = theme

export const ServiceResult = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space.lg};
`

export const Divider = styled.div`
  background-color: ${color.grayscale.lighter};
  height: ${space.xs2};
  margin-bottom: ${space.sm};
`

export const ServiceHeader = styled.div<{ type: string }>`
  width: 100%;
  background-color: ${(props) => (props.type === 'premium' ? color.grayscale.goldenBrown : color.grayscale.blue)};
  height: ${space.xl4};
  padding-left: ${space.md};
  padding-top: ${space.md};
  border-radius: ${borders.radius.lg} ${borders.radius.lg} 0 0;
`

export const CardInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  padding: ${space.lg} ${space.lg} ${space.xl} ${space.lg};
  background-color: ${color.grayscale.white};
  border: 2px solid ${color.grayscale.headerGray};

  u {
    font-weight: ${font.weight.medium};
  }

  i {
    color: ${color.text.weak};
  }
`

export const Description = styled.div`
  display: flex;
  gap: ${space.xs};
  margin: 0 ${space.xs2};
`

export const CardFooter = styled.div``

export const PrintData = styled.div`
  display: flex;
  background-color: ${color.grayscale.lightBlue};
  border-radius: ${borders.radius.xl};
  border: 2px solid #c1dee5;
`

export const PrintDataTextWrapper = styled.div`
  padding: 1.3rem 0 1.3rem 1.3rem;

  .button {
    border-radius: ${space.xl};
    height: 35px;
    padding: 0 0.5rem;
  }

  .dataDeliveryLink {
    display: flex;
    width: 100%;
    gap: ${space.md};
  }
`

export const PrintDataImageWrapper = styled.div``

export const PrintDataButtonWrapper = styled.div`
  padding-top: ${space.md};
  display: flex;
`
