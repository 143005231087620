import { FC } from 'react'
import { Text } from '@/client/components'
import * as S from './styles'
import { ITitle } from './types'

export const Title: FC<ITitle> = ({ title, subtitle }) => (
  <S.Title>
    <Text as='h1' size='h2' family='heading' weight='bold' align='center'>
      {title}
    </Text>
    {subtitle && (
      <Text as='h2' color='primary' size='xlg' family='heading' weight='medium' align='center'>
        {subtitle}
      </Text>
    )}
  </S.Title>
)
