import styled from 'styled-components'
import { theme } from '@/client/components'
const { space, breakpoint } = theme

export const Title = styled.div`
  position: relative;
  width: 100%;
  padding: ${space.xl3} ${space.xl2} 8rem;

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.xl6} 0 8rem;
  }
`
