import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useContext } from 'react'
import Image from 'next/image'
import { Alert, Box, Row, Col, Icon, Onboarding, Placeholder, Text, Tabs, Button } from '@/client/components'
import { CartContext } from '@/client/context/cart'
import { useMutatePrices, useMutateAddCartItem, useMutateUpdateCartItem, useGetUserInfos } from '@/client/hooks'
import { useGetCalculatorInfos, useGetPrices, useRouterPush, useWindowSize } from '@/client/hooks'
import { format, ROUTER_PATH } from '@/client/utils'
import { CalculatorStep } from './CalculatorStep'
import { updateInitialSelection } from './helper'
import { Options } from './Options'
import { Result } from './Result'
import { ResultFooter } from './ResultFooter'
import * as S from './styles'
import { Summary } from './Summary'
import { Title } from './Title'
import { CalculatorProps, Steps } from './types'
import { usePackageInfos } from '@/client/hooks/generalPackages'
import { ServiceResult } from './ServiceResult'

export const Calculator: FC<CalculatorProps> = ({
  hasOpenClose = false,
  hasTitle = false,
  serviceType,
  isArtworkService = false
}) => {
  const calculatorRef = useRef<HTMLDivElement>(null)
  const settingsRef = useRef<HTMLDivElement>(null)
  const [step, setStep] = useState<Steps>(Steps.SIZE_AND_FORMAT)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const [isOpenCalculator, setIsOpenCalculator] = useState<boolean>(false)
  const [startTour, setStartTour] = useState<boolean>(false)
  const [resetState, setResetState] = useState<boolean>(false)
  const [generalPackageData, setGeneralPackageData] = useState<any>(null)
  const windowSize = useWindowSize()
  const isXlBreakpoint = windowSize?.width > 992
  const { locale, defaultLocale, routerPush } = useRouterPush()
  const { data } = useGetPrices(locale)
  const { data: calculatorInfos } = useGetCalculatorInfos(locale || defaultLocale)
  const { data: generalPackages } = usePackageInfos(locale || defaultLocale)
  const { updateSelection, selection } = useContext(CartContext)
  const { isLoading, mutate } = useMutatePrices(locale)
  const openCalculatorRef = useRef<HTMLDivElement>(null)
  const { mutate: addCartItem } = useMutateAddCartItem()
  const { mutate: updateCartItem } = useMutateUpdateCartItem()
  const { mutate: updatePrices } = useMutatePrices(locale)
  const isLgBreakpoint = windowSize?.width > 768

  const calculatorTabs = () => {
    const steps = Object.entries(calculatorInfos?.steps || []).map((step, index) => ({
      id: step[0],
      key: index + 1,
      name: String(step[1].title)
    }))

    const getFilteredSteps = (desiredIds) => {
      return steps
        .filter((step) => desiredIds.includes(step.id))
        .map((step, index) => {
          if (step.id !== 'step_8' && step.id !== 'step_9') {
            return {
              ...step,
              key: index + 1
            }
          } else {
            return step
          }
        })
    }

    switch (serviceType) {
      case 'basic':
        const basicDesiredIds = ['step_1', 'step_3', 'step_4', 'step_8', 'step_9']
        return getFilteredSteps(basicDesiredIds)
      case 'premium':
        const premiumDesiredIds = ['step_1', 'step_3', 'step_4', 'step_5', 'step_7', 'step_8', 'step_9']
        return getFilteredSteps(premiumDesiredIds)
      case 'smart':
        const smartDesiredIds = ['step_1', 'step_3', 'step_4', 'step_8', 'step_9']
        return getFilteredSteps(smartDesiredIds)
      case 'pro':
        const proDesiredIds = ['step_1', 'step_3', 'step_4', 'step_5', 'step_8', 'step_9']
        return getFilteredSteps(proDesiredIds)
      default:
        return steps
    }
  }

  const handleServiceTypeChanges = (type, selection) => {
    switch (type) {
      case 'basic':
        selection['proof'] = 2
        break
      case 'premium':
        selection['proof'] = 4
        break
      case 'smart':
        selection['artwork_service'] = 101
        selection['job_type'] = 10
        break
      case 'pro':
        selection['artwork_service'] = 102
        selection['job_type'] = 10
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (data?.initialSelection && !selection) {
      const newSelection = updateInitialSelection(data?.initialSelection)
      serviceType && (newSelection['quantity'] = 10)
      isArtworkService && (newSelection['recipient'] = '0')

      handleServiceTypeChanges(serviceType, newSelection)

      updateSelection(newSelection)
      mutate(newSelection)
    }

    if (!generalPackageData) {
      setGeneralPackageData(generalPackages || null)
    }
  }, [data?.initialSelection, selection, mutate, updateSelection, generalPackages])

  const scrollToCalculator = () => {
    calculatorRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  // Default Calculator
  const handleSetStep = useCallback(
    (step: Steps) => {
      setStep(step)
      if (calculatorRef && calculatorRef.current) {
        // Disabling smooth scroll temporarily to the calculator.
        // Uncomment to restore smooth scrolling.
        // requestAnimationFrame(scrollToCalculator)
        calculatorRef.current.scrollIntoView()
      }
    },
    [calculatorRef]
  )

  const resetAll = useCallback(() => {
    const newSelection = updateInitialSelection(data?.initialSelection)
    newSelection.order_name = ''
    newSelection.artwork_service_project_name = ''
    newSelection.first_name = ''
    newSelection.surname = ''
    newSelection.email = ''
    newSelection.recipient = ''
    newSelection.artwork_service_description = ''

    handleServiceTypeChanges(serviceType, newSelection)

    updateSelection(newSelection)
    mutate(newSelection)
    setResetState(true)
  }, [data?.initialSelection])

  const handleOpenCalculator = useCallback(() => {
    setIsOpenCalculator((oldState) => {
      if (oldState && calculatorRef && calculatorRef.current) {
        calculatorRef.current.scrollIntoView({ behavior: 'smooth' })
      }
      return !oldState
    })
  }, [])

  const handleBackStep = useCallback(() => {
    if (step > Steps.SIZE_AND_FORMAT) {
      switch (true) {
        case serviceType === 'premium' && step === 8:
          handleSetStep(step - 3)
          break
        case serviceType === 'basic' && step === 8:
          handleSetStep(step - 5)
          break
        case serviceType === 'smart' && step === 8:
          handleSetStep(step - 5)
          break
        case serviceType === 'pro' && step === 8:
          handleSetStep(step - 4)
          break
        default:
          handleSetStep(step - 1)
          break
      }
    }
  }, [setStep, step])

  const handleStep = useCallback(() => {
    if (step !== Steps.SUMMARY) {
      switch (true) {
        case serviceType === 'premium' && step === 5:
          handleSetStep(step + 3)
          break
        case serviceType === 'basic' && step === 3:
          handleSetStep(step + 5)
          break
        case serviceType === 'smart' && step === 3:
          handleSetStep(step + 5)
          break
        case serviceType === 'pro' && step === 4:
          handleSetStep(step + 4)
          break
        default:
          handleSetStep(step + 1)
          break
      }
    }

    if (step === Steps.SUMMARY && serviceType) {
      let newSelection = updateInitialSelection(data?.initialSelection)

      updateSelection(newSelection)
      updatePrices(newSelection)

      newSelection = selection
      handleServiceTypeChanges(serviceType, newSelection)

      addCartItem(newSelection)
      routerPush(ROUTER_PATH.CART[defaultLocale])
    }
  }, [
    step,
    setStep,
    updatePrices,
    routerPush,
    addCartItem,
    updateCartItem,
    data?.initialSelection,
    updateSelection,
    selection
  ])

  const options = useMemo(
    () => [...(data?.calculatorFields || []), ...(data?.optionFields || [])],
    [data?.calculatorFields, data?.optionFields]
  )

  useEffect(() => {
    const handleClick = () => setIsOpenCalculator(true)
    openCalculatorRef.current?.addEventListener('mousedown', handleClick)
    return () => {
      openCalculatorRef.current?.removeEventListener('mousedown', handleClick)
    }
  }, [!!data && calculatorInfos && selection, openCalculatorRef.current, setIsOpenCalculator])

  const getProgress = useCallback(
    (step: number) => (calculatorTabs().length === 0 ? 15 : step * (100 / calculatorTabs().length)),
    [step]
  )

  return (
    <>
      {startTour && calculatorInfos && (
        <Onboarding
          containerRef={settingsRef}
          beforeChange={setStep}
          finishTour={() => setStartTour(false)}
          texts={calculatorInfos?.tour}
        />
      )}
      <div ref={settingsRef}>
        <Row ref={calculatorRef} className='tour-calculator' name='tour-calculator'>
          {!calculatorInfos && (
            <>
              <Col xs={12} xl={8}>
                <Placeholder kind='card' height='30rem' />
              </Col>

              <Col xs={12} xl={4}>
                <Placeholder kind='card' height='30rem' />
              </Col>
            </>
          )}
          {!!data && calculatorInfos && selection && (
            <>
              {hasTitle && calculatorInfos?.title && (
                <Title title={calculatorInfos.title} subtitle={calculatorInfos.subtitle} />
              )}

              <Col xs={12} xl={8}>
                <Box shadow>
                  <Tabs
                    stepActive={step}
                    steps={calculatorTabs()}
                    price={format.currency(data?.price.total)}
                    resume={calculatorInfos.resume.footer_message_mobile}
                    action={setStep}
                    kind={'withIcon'}
                    serviceType={serviceType}
                    isDisabled={
                      isLoading ||
                      !(data?.price?.priceTable?.length > 0) ||
                      (step === Steps.OPTIONS &&
                        (isArtworkService
                          ? !selection.artwork_service_description || !selection.artwork_service_project_name
                          : selection.artwork_service === 0 || !selection.order_name))
                    }
                  />

                  <S.ProgressBar value={getProgress(step)} max={100} />

                  <>
                    <S.Body ref={openCalculatorRef}>
                      <>
                        {data && (
                          <CalculatorStep
                            fields={data?.calculatorFields}
                            texts={calculatorInfos.steps.step_8}
                            step={step}
                            randomText={calculatorInfos.random_text}
                            initialSelection={data?.initialSelection}
                            reset={resetState}
                            setResetFalse={() => setResetState(false)}
                            serviceType={serviceType}
                            artworkServiceText={calculatorInfos.artwork_service_model}
                          />
                        )}
                        {step === Steps.OPTIONS && data && (
                          <S.OptionWrapperBase>
                            <S.OptionsWrapper>
                              <>
                                <Options
                                  fields={data?.optionFields}
                                  texts={calculatorInfos.steps.step_8}
                                  wpText={generalPackages}
                                  randomText={calculatorInfos.random_text}
                                  isArtworkService={isArtworkService}
                                  artworkServiceText={calculatorInfos.artwork_service_model}
                                  initialSelection={data?.initialSelection}
                                />
                              </>
                            </S.OptionsWrapper>
                          </S.OptionWrapperBase>
                        )}
                        {step === Steps.SUMMARY && data && (
                          <Summary
                            calculatorFields={data?.calculatorFields}
                            optionFields={data?.optionFields}
                            setStep={handleSetStep}
                            texts={calculatorInfos.steps.step_9}
                            randomText={calculatorInfos.random_text}
                            isArtworkService={isArtworkService}
                          />
                        )}
                        <S.BorderDivider>
                          <Row style={{ paddingTop: '2rem' }}>
                            <Col xs={12} xl={3}>
                              <Button kind='weak' isPill={true} onMouseDown={resetAll} id={'calc_b_reload'}>
                                <Icon name='reload' />
                                {calculatorInfos?.random_text?.refresh_button}
                              </Button>
                            </Col>
                            <Col xs={12} xl={9}>
                              <S.GeneralWrap>
                                {/* default calculator bottom buttons */}
                                <Button
                                  kind='weak'
                                  isPill={true}
                                  onClick={handleBackStep}
                                  disabled={isLoading || step === Steps.SIZE_AND_FORMAT}
                                  id={step !== 1 ? 'calc_b_prev' + `${step - 1}` : 'calc_b_prev'}
                                >
                                  <Icon name='go-back' /> {calculatorInfos?.random_text?.backward_button}
                                </Button>
                                {step !== Steps.SUMMARY && !serviceType && (
                                  <Button
                                    kind='warning'
                                    isPill={true}
                                    onClick={handleStep}
                                    disabled={
                                      isLoading ||
                                      !(data?.price?.priceTable?.length > 0) ||
                                      (step === Steps.OPTIONS &&
                                        (isArtworkService
                                          ? !selection.artwork_service_description ||
                                            !selection.artwork_service_project_name
                                          : selection.artwork_service === 0 || !selection.order_name))
                                    }
                                    id={'calc_b_next' + step}
                                  >
                                    {calculatorInfos?.random_text?.next_button} <Icon name='arrow-right' />
                                  </Button>
                                )}
                                {serviceType && (
                                  <Button
                                    kind='warning'
                                    isPill={true}
                                    onClick={handleStep}
                                    disabled={
                                      isLoading ||
                                      !(data?.price?.priceTable?.length > 0) ||
                                      (step === Steps.OPTIONS &&
                                        (isArtworkService
                                          ? !selection.artwork_service_description ||
                                            !selection.artwork_service_project_name
                                          : selection.artwork_service === 0 || !selection.order_name))
                                    }
                                    id={'calc_b_next' + step}
                                  >
                                    {calculatorInfos?.random_text?.next_button} <Icon name='arrow-right' />
                                  </Button>
                                )}
                              </S.GeneralWrap>
                            </Col>
                          </Row>
                        </S.BorderDivider>

                        <>
                          {calculatorInfos?.steps[`step_${step}`]['first_text'] && (
                            <S.BorderDivider>
                              <S.GeneralWrap align='flex-start'>
                                <S.ImageAligner>
                                  <Image
                                    src={`/icons/grey-info.svg`}
                                    alt='info'
                                    width={20}
                                    height={20}
                                    style={{ marginTop: '1.2 rem' }}
                                  />
                                </S.ImageAligner>
                                <Text size='xs' weight='normal' color='weak' margin='0'>
                                  {calculatorInfos?.steps[`step_${step}`]['first_text']}
                                </Text>
                              </S.GeneralWrap>
                            </S.BorderDivider>
                          )}
                          {calculatorInfos?.steps[`step_${step}`]['second_text'] && (
                            <S.BorderDivider>
                              <S.Paragraph
                                dangerouslySetInnerHTML={{
                                  __html: calculatorInfos?.steps[`step_${step}`]['second_text']
                                }}
                              />
                            </S.BorderDivider>
                          )}
                        </>
                      </>
                    </S.Body>

                    {/*  Side box */}
                    {!isXlBreakpoint && data?.price && (
                      <>
                        {serviceType ? (
                          <ServiceResult
                            proofing_service_model={calculatorInfos.proofing_service_model}
                            type={serviceType}
                          />
                        ) : (
                          <ResultFooter
                            step={step}
                            setStep={handleSetStep}
                            setIsAlertOpen={setIsAlertOpen}
                            isLoading={isLoading}
                            disableNextStep={!(data?.price?.priceTable?.length > 0)}
                            texts={{
                              button: calculatorInfos?.resume?.button,
                              extra: calculatorInfos?.resume?.extra,
                              help: calculatorInfos?.resume?.help,
                              more_info: calculatorInfos?.resume?.more_info
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                </Box>
              </Col>
              {/*  Side box */}

              {isXlBreakpoint && data?.price && (
                <Col xs={12} xl={4}>
                  {serviceType ? (
                    <ServiceResult proofing_service_model={calculatorInfos.proofing_service_model} type={serviceType} />
                  ) : (
                    <Result
                      result={data.price}
                      step={step}
                      setStep={handleSetStep}
                      setIsAlertOpen={setIsAlertOpen}
                      fields={options}
                      texts={calculatorInfos.resume}
                      error_messages={calculatorInfos?.error_messages}
                    />
                  )}
                </Col>
              )}
            </>
          )}
        </Row>

        {isAlertOpen && calculatorInfos && (
          <Alert
            size='large'
            cancelButtonName={calculatorInfos.purchase_confirmation_modal.cancel_button_label}
            confirmButtonName={calculatorInfos.purchase_confirmation_modal.confirmation_button_label}
            close={() => routerPush(ROUTER_PATH.CART[defaultLocale])}
            action={() => setIsAlertOpen(false)}
          >
            <Image src='/icons/cart-checked.svg' alt='Cart checked' width='80' height='78' />
            <Text size='xxlg' family='heading' weight='bold' align='center' margin='2rem 0'>
              {calculatorInfos.purchase_confirmation_modal.title}
            </Text>
          </Alert>
        )}
      </div>
    </>
  )
}
