import { FC, Fragment, useCallback, useContext } from 'react'
import { Button, Icon, Text, Image } from '@/client/components'
import { CartContext } from '@/client/context/cart'
import * as S from './styles'
import {
  getOptionValue,
  getOptionValueForShortSummary,
  getOptionsField,
  isExcludingField,
  getCorrectDimensionType
} from '../helper'
import { Steps, SummaryChildProps } from '../types'

export const Summary: FC<SummaryChildProps> = ({
  calculatorFields,
  optionFields,
  setStep,
  texts,
  randomText,
  isArtworkService
}) => {
  const { selection } = useContext(CartContext)
  const [order, ...restOptionFields] = optionFields

  const keysToExclude = ['paper', 'quantity', 'dimension', 'diameter']

  const selectedOption = (key) => {
    const name = getOptionsField(calculatorFields, key).find((option) => option.id === selection[key])?.name
    return name
  }

  return (
    <>
      <S.Summary>
        <S.SummaryHeader>
          {isArtworkService ? (
            <>
              <Text size='lg' family='heading' weight='bold' margin='0'>
                {randomText?.project_name}
              </Text>
            </>
          ) : (
            <>
              <Text size='lg' family='heading' weight='bold' margin='0'>
                {order.displayName}
              </Text>
            </>
          )}

          <Button size='small' kind='weak' isOutlined onClick={() => setStep(Steps.OPTIONS)}>
            <Icon name='edit' />
            {texts.edit_button}
          </Button>
        </S.SummaryHeader>
        <S.SummaryBody>
          {isArtworkService ? (
            <>
              <Text size='sm' color='weak' margin='0 0 0.125rem'>
                {randomText?.project_name}
              </Text>
              <Text weight='medium' margin='0 0 1.5rem'>
                {selection.artwork_service_project_name}
              </Text>
            </>
          ) : (
            <>
              {order.options.map(({ name, key }) => (
                <Fragment key={key}>
                  <Text size='sm' color='weak' margin='0 0 0.125rem'>
                    {name}
                  </Text>
                  <Text weight='medium' margin='0 0 1.5rem'>
                    {selection[key]}
                  </Text>
                </Fragment>
              ))}
            </>
          )}
        </S.SummaryBody>
      </S.Summary>

      <S.Summary>
        <S.SummaryHeader>
          <Text size='lg' family='heading' weight='bold' margin='0'>
            {texts.tag}
          </Text>
          <Button size='small' kind='weak' isOutlined onClick={() => setStep(Steps.SIZE_AND_FORMAT)}>
            <Icon name='edit' />
            {texts.edit_button}
          </Button>
        </S.SummaryHeader>

        <>
          <S.MainSummary>
            <S.ShortSummary>
              <S.PositionSvg>
                <Image src={`/icons/rolls-icon.svg`} width='319' height='490' alt='rolls' />
                <S.PositionTextInSvg>
                  <Text size='sm' color='normal' margin='0 0 3rem 0' weight='bold'>
                    {randomText?.quick_summary}
                  </Text>
                  <>
                    <>
                      <>
                        <S.IconAligner>
                          <Icon name='dimension' size='s5' />
                          <Text size='sm' color='weak' margin='0 0 1rem 1rem'>
                            {
                              calculatorFields.find(
                                (field) => field.key === getCorrectDimensionType(selection, calculatorFields)
                              )?.displayName
                            }
                          </Text>
                        </S.IconAligner>
                        <Text
                          size='xlg'
                          color='primary'
                          margin='0 0 1rem 0'
                          weight='bold'
                          style={{ borderBottom: '2px dashed #EAEDF0' }}
                        >
                          {getOptionValueForShortSummary(
                            selection,
                            getCorrectDimensionType(selection, calculatorFields)
                          )}
                        </Text>
                      </>

                      <>
                        <S.IconAligner>
                          <Icon name='quantity-icon' size='s5' />
                          <Text size='sm' color='weak' margin='0 0 1rem 1rem'>
                            {calculatorFields.find((field) => field.key === 'quantity')?.displayName}
                          </Text>
                        </S.IconAligner>
                        <Text
                          size='xlg'
                          color='primary'
                          margin='0 0 1rem 0'
                          weight='bold'
                          style={{ borderBottom: '2px dashed #EAEDF0' }}
                        >
                          {getOptionValueForShortSummary(selection, 'quantity')}
                        </Text>
                      </>
                      <>
                        <S.IconAligner>
                          <Icon name='material-icon' size='s5' />
                          <Text size='sm' color='weak' margin='0 0 1rem 1rem'>
                            {calculatorFields.find((field) => field.key === 'paper')?.displayName}
                          </Text>
                        </S.IconAligner>
                        <Text
                          size='xs'
                          color='primary'
                          margin='0 0 1rem 0'
                          weight='bold'
                          style={{ borderBottom: '2px dashed #EAEDF0' }}
                        >
                          {selectedOption('paper')}
                        </Text>
                      </>
                    </>
                  </>
                </S.PositionTextInSvg>
              </S.PositionSvg>
            </S.ShortSummary>
            <S.SummaryBody>
              <S.DetailedSummary>
                {calculatorFields.map(
                  ({ key, displayName, options, exclude }) =>
                    isExcludingField(exclude, selection) &&
                    !keysToExclude.includes(key) && (
                      <Fragment key={key}>
                        <div style={{ display: 'inline-block' }}>
                          <Text size='sm' color='weak' margin='0 0 0.125rem'>
                            {displayName}
                          </Text>
                          <Text weight='medium' margin='0 0 1.5rem'>
                            {getOptionValue(options, selection, key)}
                          </Text>
                        </div>
                        <br />
                      </Fragment>
                    )
                )}
                <>
                  <div>
                    <Text size='sm' color='weak' margin='0 0 0.125rem'>
                      {randomText?.notes}
                    </Text>
                    <Text weight='medium' margin='0 0 1.5rem'>
                      {selection.artwork_service_description ? selection.artwork_service_description : '-'}
                    </Text>
                  </div>
                  <br />
                </>
              </S.DetailedSummary>
            </S.SummaryBody>
          </S.MainSummary>
        </>
      </S.Summary>

      <>
        {!isArtworkService && (
          <S.Summary>
            <S.SummaryHeader>
              <Text size='lg' family='heading' weight='bold' margin='0'>
                {texts.options}
              </Text>
              <Button size='small' kind='weak' isOutlined onClick={() => setStep(Steps.OPTIONS)}>
                <Icon name='edit' />
                {texts.edit_button}
              </Button>
            </S.SummaryHeader>

            <S.SummaryBody>
              {restOptionFields.map(({ key, displayName, options }) => (
                <Fragment key={key}>
                  <Text size='sm' color='weak' margin='0 0 0.125rem'>
                    {displayName}
                  </Text>
                  <Text weight='medium' margin='0 0 1.5rem'>
                    {getOptionValue(options, selection, key)}
                  </Text>
                </Fragment>
              ))}
            </S.SummaryBody>
          </S.Summary>
        )}
      </>
    </>
  )
}
