import { ChangeEvent, FC, Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Checkbox,
  Content,
  Icon,
  Input,
  List,
  ListItem,
  SelectCard,
  SelectCardCheckBox,
  Text,
  Textarea,
  Tooltip
} from '@/client/components'
import { CartContext } from '@/client/context/cart'
import { useMutatePrices } from '@/client/hooks'
import { IFieldOption } from '@/client/types'
import * as S from '../styles'
import { FieldType, SettingsChildProps } from '../types'
import { CardPackageSimple } from '@/client/components/CardPackageSimple'

export const Options: FC<SettingsChildProps> = ({
  fields,
  texts,
  wpText,
  isArtworkService,
  artworkServiceText,
  initialSelection
}) => {
  const { locale } = useRouter()
  const { mutate } = useMutatePrices(locale)
  const { updateSelection, selection } = useContext(CartContext)
  const [checkRecipient, setCheckedRecipient] = useState<boolean>(false)

  const cardText = (type: string) => {
    return wpText.packages.find((packages) => packages.name === type)
  }

  const handleChange = useCallback(
    (optionId, value) => {
      let recipientValue
      if (optionId === 'recipient') {
        if (checkRecipient) {
          recipientValue = '1'
          setCheckedRecipient(false)
        } else {
          recipientValue = '0'
          setCheckedRecipient(true)
        }
      }

      updateSelection({
        ...selection,
        [optionId]: value,
        ['recipient']: recipientValue
      })

      handleBlur(optionId, value)
    },
    [selection, updateSelection, mutate, checkRecipient]
  )

  const handleBlur = useCallback(
    (optionId, value) => {
      mutate({
        ...selection,
        [optionId]: value
      })
    },
    [selection, mutate]
  )

  const handleSelect = useCallback(
    (optionId, value, relatedField, relatedFieldId) => {
      const newSelection = {
        ...selection,
        [optionId]: value,
        [relatedField]: relatedFieldId
      }
      updateSelection(newSelection)
      mutate(newSelection)
    },
    [selection, updateSelection, mutate]
  )

  const handleSelectCheckbox = useCallback(
    (optionId, nextFieldId, relatedField, relatedFieldId) => {
      const newSelection = {
        ...selection,
        [optionId]: nextFieldId,
        [relatedField]: relatedFieldId
      }

      updateSelection(newSelection)
      mutate(newSelection)
    },
    [mutate, selection, updateSelection]
  )

  const handleChangeCheckbox = useCallback(
    (optionId, value) => {
      let newOptions = selection[optionId] as string[]
      const isChecked = newOptions.some((item: string) => item === value)

      if (isChecked) {
        newOptions = newOptions.filter((item: string) => item !== value)
      } else {
        newOptions.push(value)
      }

      const newSelection = {
        ...selection,
        [optionId]: newOptions
      }

      updateSelection(newSelection)
      mutate(newSelection)
    },
    [selection, updateSelection, mutate]
  )

  const isDisabled = useCallback(
    (disableList): boolean => {
      if (!disableList) return false
      const isDisabled = disableList.some(({ key, value }) => selection[key] === value)
      return isDisabled
    },
    [selection]
  )

  const handleAssemblyChange = useCallback(
    (optionId, value) => {
      const oppositeSelection = optionId === 'maximumRollDiameter' ? 'labelsNumberPerRoll' : 'maximumRollDiameter'

      const newSelection = {
        ...selection,
        [optionId]: value,
        [oppositeSelection]: '-'
      }

      updateSelection(newSelection)
      mutate(newSelection)
    },
    [updateSelection, selection, mutate]
  )

  useEffect(() => {
    fields.forEach((field) => {
      if (!field.force_value) return
      const { id, key, value, nextFieldId, relatedFieldId, relatedField } = field.force_value
      const onChangeTypes: Record<FieldType, () => void> = {
        ['textarea']: () => handleChange(key, value),
        ['input-number']: () => handleAssemblyChange(id, value),
        ['range']: () => handleChange(id, value),
        ['select-radio']: () => handleSelect(field.key, value, relatedField, relatedFieldId),
        ['checkbox']: () => handleChangeCheckbox(field.key, value),
        ['select-radio-vertical']: () => {},
        ['select-card-checkbox']: () => handleSelectCheckbox(field.key, nextFieldId, relatedField, relatedFieldId)
      }
      onChangeTypes[field.type]()
    })
  }, [fields])

  return (
    <>
      <>
        {!isArtworkService && (
          <>
            {fields.map((field) => (
              <S.Block key={field.key} className={`tour-${field.key}`}>
                <Text size='lg' family='heading' weight='bold' margin='0 0 1.5rem'>
                  {field.displayName}

                  {field?.hint && (
                    <S.Hint>
                      <Tooltip title={field.hint}>
                        <Icon name='info' />
                      </Tooltip>
                    </S.Hint>
                  )}
                </Text>

                <div>
                  {field.type === 'textarea' && (
                    <S.Option direction='column'>
                      {field?.options?.map(({ id, name, key, dimension }: IFieldOption) => (
                        <Textarea
                          key={id}
                          id={id}
                          isBlock
                          name={key}
                          hasError={!selection[key] || (selection[key] as string).trim().length === 0}
                          label={name}
                          value={selection[key] as string}
                          dimension='small'
                          onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                            handleChange(key, event?.currentTarget?.value)
                          }}
                          style={{ backgroundColor: '#EAEDF0', borderRadius: '10px' }}
                          className={field.key}
                          // Changelog
                          // OnBlur has been commented since it affects with focus and click event.
                          // handleBlur() event has been added to handleChange() method.
                          // onBlur={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                          //   handleBlur(key, event?.currentTarget?.value)
                          // }}
                        />
                      ))}
                    </S.Option>
                  )}

                  {field.type === 'input-number' && field.key === 'assembly' && (
                    <S.Option isFull>
                      {field?.options?.map(({ id, name, unit, min, max }: IFieldOption, index: number) => (
                        <Fragment key={id}>
                          <Input
                            isBlock
                            dimension='large'
                            type='number'
                            id={id}
                            name={name}
                            min={min}
                            max={max}
                            label={`${name} (${unit})`}
                            value={selection[id] as string}
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                              handleAssemblyChange(id, event?.currentTarget?.value)
                            }}
                          />

                          {index === 0 && <S.Divider>{texts.choose_one_set}</S.Divider>}
                        </Fragment>
                      ))}
                    </S.Option>
                  )}

                  {field.type === 'range' && (
                    <S.Option>
                      {field?.options?.map(({ id, name }: IFieldOption) => (
                        <Input
                          dimension='large'
                          key={id}
                          id={id}
                          name={name}
                          label={name}
                          value={selection[id] as string}
                          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            handleChange(id, event?.currentTarget?.value)
                          }}
                          onBlur={(event: ChangeEvent<HTMLInputElement>): void => {
                            handleBlur(id, event?.currentTarget?.value)
                          }}
                        />
                      ))}
                    </S.Option>
                  )}

                  {field.type === 'select-radio' && field.key !== 'artwork_service' && (
                    <S.Option className='selected-radio-wrapper'>
                      {field?.options?.map(
                        ({ id, name, description, icon, relatedField, relatedFieldId }: IFieldOption) => (
                          <SelectCard
                            key={id}
                            id={id}
                            value={id}
                            name={field.key}
                            label={name}
                            description={description}
                            checked={id === selection[field.key]}
                            onChange={() => handleSelect(field.key, id, relatedField, relatedFieldId)}
                            iconName={icon}
                          />
                        )
                      )}
                    </S.Option>
                  )}

                  {/** Adding additional WP content for artwork_service */}
                  {field.type === 'select-radio' && field.key === 'artwork_service' && (
                    <>
                      <S.OptionAligner>
                        <S.Option direction='row' isFull>
                          {field?.options?.map(
                            ({
                              id,
                              name,
                              description,
                              icon,
                              key,
                              lock,
                              disabled,
                              relatedField,
                              relatedFieldId
                            }: IFieldOption) => (
                              <>
                                {key !== 'no_artwork_service' && (
                                  <S.PackageList name={name}>
                                    <SelectCard
                                      key={id}
                                      id={id}
                                      value={id}
                                      name={field.key}
                                      label={name}
                                      description={description}
                                      disabled={lock == 1 || isDisabled(disabled)}
                                      checked={id === selection[field.key]}
                                      onChange={() => handleSelect(field.key, id, relatedField, relatedFieldId)}
                                      iconName={icon}
                                    />

                                    {name === 'Smart' && (
                                      <CardPackageSimple
                                        key={cardText('Smart').name}
                                        kind={cardText('Smart').type}
                                        name={cardText('Smart').name}
                                        description={cardText('Smart').description}
                                        list={cardText('Smart').service_list}
                                        price={cardText('Smart').price}
                                        prefix={cardText('Smart').price_prefix}
                                        suffix={cardText('Smart').price_suffix}
                                        observation={cardText('Smart').production_time}
                                        link={cardText('Smart').link}
                                        buttonLabel={cardText('Smart').button_label}
                                      />
                                    )}

                                    {name !== 'Smart' && (
                                      <CardPackageSimple
                                        key={cardText('Pro').name}
                                        kind={cardText('Pro').type}
                                        name={cardText('Pro').name}
                                        description={cardText('Pro').description}
                                        list={cardText('Pro').service_list}
                                        price={cardText('Pro').price}
                                        prefix={cardText('Pro').price_prefix}
                                        suffix={cardText('Pro').price_suffix}
                                        observation={cardText('Pro').production_time}
                                        link={cardText('Pro').link}
                                        buttonLabel={cardText('Pro').button_label}
                                      />
                                    )}
                                  </S.PackageList>
                                )}
                              </>
                            )
                          )}
                        </S.Option>
                        <>
                          <S.CleanCard>
                            <Text size='md' family='heading' weight='bold' margin='0 0 1.5rem'>
                              {wpText?.about_packages?.title}
                            </Text>
                            <Text weight='medium' margin='0 0 1.5rem'>
                              {wpText?.about_packages?.subtitle}
                            </Text>

                            <List>
                              {wpText?.about_packages?.service_list.map(({ text, note }) =>
                                note ? (
                                  <div key={text}>
                                    <ListItem>
                                      <span dangerouslySetInnerHTML={{ __html: text }} />
                                    </ListItem>
                                    {note && <Content content={note} style={{ marginBottom: '0.75rem' }} />}
                                  </div>
                                ) : (
                                  <ListItem key={text}>
                                    <span dangerouslySetInnerHTML={{ __html: text }} />
                                  </ListItem>
                                )
                              )}
                            </List>
                          </S.CleanCard>
                        </>
                      </S.OptionAligner>

                      {/* Adding comment field for pro and smart service */}
                      {selection.artwork_service !== initialSelection.artwork_service &&
                        selection.no_artwork_service === initialSelection.no_artwork_service && (
                          <S.Option direction='column'>
                            <Textarea
                              key={'artwork_service_description'}
                              id={'artwork_service_description'}
                              isBlock
                              name={'artwork_service_description'}
                              hasError={
                                !selection['artwork_service_description'] ||
                                (selection['artwork_service_description'] as string).trim().length === 0
                              }
                              label={artworkServiceText.artwork_service_options.notes.placeholder}
                              value={selection['artwork_service_description'] as string}
                              dimension='medium'
                              onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                                handleChange('artwork_service_description', event?.currentTarget?.value)
                              }}
                              style={{ backgroundColor: '#EAEDF0', borderRadius: '10px' }}
                              className={'artwork_service_description'}
                            />
                          </S.Option>
                        )}
                    </>
                  )}

                  {field.type === 'checkbox' && (
                    <S.Option direction='column'>
                      {field?.options?.map(({ id, name, description }: IFieldOption) => (
                        <Checkbox
                          key={id}
                          id={id}
                          value={id}
                          name={field.key}
                          label={name}
                          description={description}
                          checked={(selection[field.key] as number[]).includes(Number(id))}
                          kind='square'
                          onChange={() => handleChangeCheckbox(field.key, id)}
                        />
                      ))}
                    </S.Option>
                  )}

                  {field.type === 'select-card-checkbox' && (
                    <>
                      {field?.options?.map(
                        ({ id, name, description, key, nextFieldId, relatedField, relatedFieldId }: IFieldOption) => (
                          <SelectCardCheckBox
                            key={id}
                            id={id}
                            value={id}
                            name={field.key}
                            label={name}
                            description={description}
                            checked={key === 'true'}
                            kind='circle'
                            subText=''
                            rightEndText=''
                            boxType='withIcon'
                            onChange={() => handleSelectCheckbox(field.key, nextFieldId, relatedField, relatedFieldId)}
                          />
                        )
                      )}
                    </>
                  )}
                </div>

                {field.error_message && (
                  <Text margin='0.8rem 0 0' color='danger' size='sm'>
                    {field.error_message}
                  </Text>
                )}
              </S.Block>
            ))}
          </>
        )}
      </>
      <>
        {/* Options Step for Smart and Pro Artwork Service */}
        {isArtworkService && (
          <>
            <S.Block>
              <Text size='lg' family='heading' weight='bold' margin='0 0 1.5rem'>
                {artworkServiceText.artwork_service_options.basic_info.title}
              </Text>
              <S.Option direction='column'>
                <Textarea
                  key={'artwork_service_project_name'}
                  id={'artwork_service_project_name'}
                  isBlock
                  name={'artwork_service_project_name'}
                  hasError={
                    !selection['artwork_service_project_name'] ||
                    (selection['artwork_service_project_name'] as string).trim().length === 0
                  }
                  label={artworkServiceText.artwork_service_options.basic_info.project_name}
                  value={selection['artwork_service_project_name'] as string}
                  dimension='small'
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                    handleChange('artwork_service_project_name', event?.currentTarget?.value)
                  }}
                  style={{ backgroundColor: '#EAEDF0', borderRadius: '10px' }}
                  className={'artwork_service_project_name'}
                />
              </S.Option>
            </S.Block>
            <S.Block>
              <Text size='lg' family='heading' weight='bold' margin='0 0 1.5rem'>
                {artworkServiceText?.artwork_service_options.recipient.title}
              </Text>
              <Text size='sm' color='weak' margin='0 0 1.5rem'>
                {artworkServiceText.artwork_service_options.recipient.text}
              </Text>
              <S.Option direction='column'>
                <Checkbox
                  id='recipient'
                  name='recipient'
                  value={selection['recipient'] as string}
                  label={artworkServiceText.artwork_service_options.recipient.checkbox}
                  checked={selection['recipient'] !== '' && checkRecipient}
                  kind='circle'
                  onChange={(event) => {
                    handleChange('recipient', event?.currentTarget?.value)
                  }}
                />
                {checkRecipient && (
                  <S.ArtworkOptionsWrapper>
                    <Input
                      isBlock
                      dimension='small'
                      type='text'
                      id='first_name'
                      name='first_name'
                      placeholder={artworkServiceText.artwork_service_options.recipient.first_name}
                      value={selection['first_name'] as string}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange('first_name', event?.currentTarget?.value)
                      }}
                      //hasError={!selection['first_name'] || (selection['first_name'] as string).trim().length === 0}
                      className={'first_name'}
                    />
                    <Input
                      isBlock
                      dimension='small'
                      type='text'
                      id='surname'
                      name='surname'
                      placeholder={artworkServiceText.artwork_service_options.recipient.last_name}
                      value={selection['surname'] as string}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange('surname', event?.currentTarget?.value)
                      }}
                      //hasError={!selection['last_name'] || (selection['last_name'] as string).trim().length === 0}
                      className={'last_name'}
                    />

                    <Input
                      isBlock
                      dimension='small'
                      type='text'
                      id='email'
                      name='email'
                      placeholder={artworkServiceText.artwork_service_options.recipient.email}
                      value={selection['email'] as string}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange('email', event?.currentTarget?.value)
                      }}
                      //hasError={!selection['email'] || (selection['email'] as string).trim().length === 0}
                      className={'email'}
                    />
                  </S.ArtworkOptionsWrapper>
                )}
              </S.Option>
            </S.Block>
            <S.Block>
              <Text size='lg' family='heading' weight='bold' margin='0 0 1.5rem'>
                {artworkServiceText.artwork_service_options.notes.title}
              </Text>
              <S.Option direction='column'>
                <Textarea
                  key={'artwork_service_description'}
                  id={'artwork_service_description'}
                  isBlock
                  name={'artwork_service_description'}
                  hasError={
                    !selection['artwork_service_description'] ||
                    (selection['artwork_service_description'] as string).trim().length === 0
                  }
                  label={artworkServiceText.artwork_service_options.notes.placeholder}
                  value={selection['artwork_service_description'] as string}
                  dimension='medium'
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                    handleChange('artwork_service_description', event?.currentTarget?.value)
                  }}
                  style={{ backgroundColor: '#EAEDF0', borderRadius: '10px' }}
                  className={'artwork_service_description'}
                />
              </S.Option>
            </S.Block>
          </>
        )}
      </>
    </>
  )
}
