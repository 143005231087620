import { FC, Fragment } from 'react'
import { Text, Icon, Image, Button, Link, List, ListItem } from '@/client/components'
import * as S from './styles'
import { ServiceResultProps } from '../types'
import { IconName } from '@/client/components/Icon/types'

export const ServiceResult: FC<ServiceResultProps> = ({ proofing_service_model, type }) => {
  return (
    <S.ServiceResult>
      <div>
        <S.ServiceHeader type={type}>
          <Text color='inverse' size='xlg' weight='semiBlack' align='left'>
            {proofing_service_model[type]?.name}
          </Text>
        </S.ServiceHeader>

        <S.CardInfos>
          {type === ('basic' || 'premium') && (
            <S.Description>
              <S.Description>
                <Icon name={proofing_service_model[type]?.label_type_icon} size='s6' color='black' />
                <Icon name={proofing_service_model[type]?.cutting_icon} size='s6' color='black' />
              </S.Description>
              <Text margin='0 0 2rem 1.5rem' weight='bold' lineHeight='1.2' size='xs'>
                <span dangerouslySetInnerHTML={{ __html: proofing_service_model[type]?.description }} />
              </Text>
            </S.Description>
          )}

          <List kind='gold'>
            {proofing_service_model[type]?.service_list.map(({ key, icon, text, isBold }) => (
              <Fragment key={key}>
                <ListItem key={text} iconName={icon as IconName} weight={isBold ? 'medium' : 'normal'} fontSize='small'>
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                </ListItem>
              </Fragment>
            ))}
          </List>

          <S.Divider />

          <S.CardFooter>
            <Text color='weak' margin='0'>
              <Text as='span' size='h3' weight='semiBlack' margin='0' padding='0'>
                {proofing_service_model[type]?.price}
              </Text>
              {` ${proofing_service_model[type]?.price_suffix}`}
            </Text>
            <Text margin='0' padding='0' lineHeight='1.2' color='black'>
              <span
                dangerouslySetInnerHTML={{ __html: proofing_service_model[type]?.production_time }}
                className='newObervationText'
              />
            </Text>
          </S.CardFooter>
        </S.CardInfos>
      </div>

      {type === ('basic' || 'premium') && (
        <S.PrintData>
          <S.PrintDataTextWrapper>
            <Text margin='0 0 0.3rem' color='weak' size='sm'>
              {proofing_service_model?.print_data_delivery.sub_heading}
            </Text>
            <Text weight='semiBlack' size='xlg' lineHeight='1.2' margin='0 0 0.3rem'>
              {proofing_service_model?.print_data_delivery.heading}
            </Text>
            <Text lineHeight='1.2' margin='0' size='sm'>
              {proofing_service_model?.print_data_delivery.long_text}
            </Text>

            <S.PrintDataButtonWrapper>
              <Button kind='warning' className='button'>
                <Link href={proofing_service_model?.print_data_delivery.link} className='dataDeliveryLink'>
                  <Text weight='bold' margin='0 0 0 0'>
                    {proofing_service_model?.print_data_delivery.button_text}
                  </Text>
                  <Icon name='arrow-right' />
                </Link>
              </Button>
            </S.PrintDataButtonWrapper>
          </S.PrintDataTextWrapper>

          <S.PrintDataImageWrapper>
            <Image src={proofing_service_model?.print_data_delivery.image.url} width='160' height='200' />
          </S.PrintDataImageWrapper>
        </S.PrintData>
      )}
    </S.ServiceResult>
  )
}
