import { FC, memo, useCallback } from 'react'
import { IconName } from '@/client/components/Icon/types'
import { useRouterPush, useAuthUser } from '@/client/hooks'
import { ROUTER_PATH } from '@/client/utils'
import * as S from './styles'
import { CardPackageProps } from './types'
import { List } from '../List/List'
import { ListItem } from '../List/ListItem'

export const CardPackageSimple: FC<CardPackageProps> = memo(
  ({
    kind = 'dark',
    name,
    description,
    list,
    price,
    prefix = '',
    suffix = '',
    observation,
    link,
    buttonLabel,
    ...rest
  }) => {
    const { routerPush, defaultLocale } = useRouterPush()
    const { isAuthenticated } = useAuthUser()

    const handleAuthorization = useCallback(() => {
      if (isAuthenticated) return routerPush(link)
      routerPush(`${ROUTER_PATH.LOGIN[defaultLocale]}?redirect=${ROUTER_PATH.PROOFING_SERVICE[defaultLocale]}`)
    }, [link, isAuthenticated, routerPush, defaultLocale])

    return (
      <S.Card kind={kind} {...rest}>
        <div>
          <S.CardHeader kind={kind}>{name}</S.CardHeader>

          <S.CardInfos>
            <List>
              {list.map(({ icon, text, isBold }) => (
                <ListItem key={text} iconName={icon as IconName} weight={isBold ? 'medium' : 'normal'} fontSize='small'>
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                </ListItem>
              ))}
            </List>
          </S.CardInfos>
        </div>
      </S.Card>
    )
  }
)

CardPackageSimple.displayName = 'CardPackageSimple'
