import styled from 'styled-components'
import { theme } from '@/client/components'
const { color, space, borders, breakpoint } = theme

export const Summary = styled.div`
  padding-top: ${space.xl};
  padding-left: ${space.xs};
  padding-right: ${space.xs};

  &:not(:last-of-type) {
    padding-bottom: ${space.md};
    border-bottom: ${borders.sizes.md} dashed ${color.grayscale.light};
  }

  @media (min-width: ${breakpoint.lg}) {
    padding-top: ${space.md};
    padding-left: ${space.lg};
    padding-right: ${space.lg};
  }
`

export const SummaryHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${space.md};
`

export const SummaryBody = styled.div`
  width: 100%;
  padding: ${space.xs} ${space.lg};
  border-radius: ${borders.radius.lg};
  background-color: ${color.grayscale.light};
`

export const MainSummary = styled.div`
  display: block;
  @media (min-width: ${breakpoint.lg}) {
    display: flex;
    justfy-content: space-between;
  }
`

export const ShortSummary = styled.div`
  position: relative;
  left: -3.5rem;
`

export const DetailedSummary = styled.div`
  column-count: 2;
`
export const PositionSvg = styled.div`
  position: relative;
`

export const PositionTextInSvg = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 6rem;
  right: 1.8rem;
`
export const IconAligner = styled.div`
  display: flex;
`
